import React, { Suspense, lazy } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { FEATURES } from '../constants'
import NakedRoute from './types/NakedRoute'
import AdminRoute from './types/AdminRoute'
import AuthenticatedRoute from './types/AuthenticatedRoute'
import NotFound404 from '../components/NotFound404'
import Login from '../pages/Auth'
import { useSelector } from 'react-redux'
import { loggedIn } from '../selectors'
import { FEATURE_FLAG } from '../constants/features'
import { Backdrop } from '@mui/material'

const Fields = lazy(() => import('../pages/Fields'))
const FieldSelection = lazy(() => import('../pages/FieldSelection'))
const FieldUploads = lazy(() => import('../pages/FieldSelection/FieldUploads'))
const Organizations = lazy(() => import('../pages/Organizations'))
const Settings = lazy(() => import('../pages/Settings'))
const NoOrganizations = lazy(() => import('../pages/Auth/NoOrganizations'))
const Samplers = lazy(() => import('../pages/Auth/Samplers'))
const OAuthRedirect = lazy(() => import('../pages/Redirect'))
const ProjectView = lazy(() => import('../pages/Projects'))
const SOCStock = lazy(() => import('../pages/SOCStock'))
const BoundaryEditor = lazy(() => import('../pages/BoundaryEditor'))
const Jobs = lazy(() => import('../pages/Jobs'))
const SamplingStratification = lazy(() =>
  import('../pages/SamplingStratification')
)
const UnassignedFields = lazy(() => import('../pages/UnassignedFields'))
const Monitor = lazy(() => import('../pages/Monitor'))

const Routes = () => {
  const isLoggedIn = useSelector(loggedIn)

  return (
    <Suspense fallback={<Backdrop />}>
      <Switch>
        <NakedRoute exact path="/">
          {isLoggedIn ? (
            <Redirect to="/field-prospecting" />
          ) : (
            <Redirect to="/login" />
          )}
        </NakedRoute>
        <NakedRoute path="/login" component={Login} />
        <AuthenticatedRoute path="/user-settings" component={Settings} />
        <AuthenticatedRoute path="/oauth-redirect" component={OAuthRedirect} />

        <AdminRoute
          path="/organizations"
          meta={{ auth: true, admin: true }}
          component={Organizations}
        />
        <AuthenticatedRoute
          path="/projects/:projectId"
          component={() =>
            FEATURE_FLAG.REACT_APP_FF_UI_PROJECTS ? (
              <ProjectView />
            ) : (
              <Redirect to="/field-prospecting" />
            )
          }
        />

        <AuthenticatedRoute
          path="/fields"
          component={() => <Fields feature_name={FEATURES.FIELDS} />}
        />

        <AuthenticatedRoute
          path="/field-prospecting"
          component={() => <FieldSelection />}
        />

        <AuthenticatedRoute
          path="/upload-fields"
          component={() => (
            <FieldUploads eature_name={FEATURES.UPLOAD_FIELDS} />
          )}
        />

        <AuthenticatedRoute path="/jobs" component={Jobs} />

        {/** These routes are only available when Workspaces are disabled. */}
        {!FEATURE_FLAG.REACT_APP_FF_UI_PROJECTS && (
          <Switch>
            <AuthenticatedRoute
              path="/fields/review"
              component={() => (
                <Fields
                  isTempField={true}
                  feature_name={FEATURES.UPLOAD_FIELDS}
                />
              )}
            />

            <AuthenticatedRoute
              path="/soc-stock"
              component={() => <SOCStock />}
            />

            <AuthenticatedRoute
              path="/sampling-stratification"
              component={() => <SamplingStratification />}
            />

            <AuthenticatedRoute
              path="/work-orders"
              component={() => <Monitor />}
            />

            <AuthenticatedRoute
              path="/unassigned-fields"
              component={() => <UnassignedFields />}
            />

            <AuthenticatedRoute
              path="/boundary-editor"
              component={() => <BoundaryEditor />}
            />
          </Switch>
        )}

        <NakedRoute path="/field-selection">
          <Redirect to="/field-prospecting" />
        </NakedRoute>
        <NakedRoute exact path="/ops">
          <Redirect to="/work-orders" />
        </NakedRoute>
        <NakedRoute path="/no_organizations" component={NoOrganizations} />
        <NakedRoute path="/samplers" component={Samplers} />
        <NakedRoute component={NotFound404} />
      </Switch>
    </Suspense>
  )
}

export default Routes
