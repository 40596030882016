import { omit as _omit, uniqBy as _uniqBy } from 'lodash-es'
import MissingFieldFormDataError from './errors/MissingFieldFormDataError'
import * as Papa from 'papaparse'
import { ATTRIBUTES_IGNORED_WHEN_EXPORTING } from '../constants'

export const groupFieldsByRanch = (fields = [], enrollmentFieldBoundaries) => {
  return fields?.reduce((acc, curr) => {
    if (curr.farm_id === null) return acc
    const found = acc.find((item) => item.farm_id === curr.farm_id)
    curr.status =
      enrollmentFieldBoundaries?.find(
        (item) => item.ag_field_info_id === curr.ag_field_uuid
      )?.boundary_status || ''

    if (!found) {
      const { farm_id, farm_name, area_in_hectares, status } = curr
      const ranch = {
        farm_id,
        farm_name,
        fields: [curr],
        area_in_hectares,
        status,
      }
      return [...acc, ranch]
    } else {
      found.fields.push(curr)
      found.area_in_hectares += curr.area_in_hectares
      if (found.status !== 'in_progress') found.status = curr.status
      return acc
    }
  }, [])
}

export const getUnassignedFields = (fields = []) => {
  return fields.filter((field) => {
    return field.farm_id === null
  })
}

export const parsePaginationFieldData = (
  fetchedFields,
  stateFields,
  page,
  customId
) =>
  page === 1
    ? [...fetchedFields]
    : _uniqBy([...stateFields, ...fetchedFields], customId || 'ag_field_uuid')

export const parseTotalCount = (fetchedCount, stateCount, page) =>
  page === 1 ? fetchedCount : parseInt(fetchedCount) + parseInt(stateCount)

export const parsePaginationSummaryData = (
  fetchedSummary,
  stateSummary,
  page
) =>
  page === 1
    ? { ...fetchedSummary }
    : Object.entries(stateSummary).reduce(
        (acc, [key, val]) => {
          acc[key] = parseFloat(val) + parseFloat(fetchedSummary[key] || 0)
          return acc
        },
        { ...stateSummary }
      )

export const getFieldGeoJSON = (geometry) => ({
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {},
      geometry: geometry,
    },
  ],
})

export const getExpectedSamplePointsGeoJSON = (expectedPoints) => ({
  type: 'FeatureCollection',
  features: expectedPoints.map((p) => {
    return {
      type: 'Feature',
      properties: {
        name: p.name,
        id: p.id,
      },
      geometry: p.location,
    }
  }),
})

export const getExpectedStrataGeoJSON = (strata) => ({
  type: 'FeatureCollection',
  features: strata.map((s) => {
    return {
      type: 'Feature',
      properties: {
        name: s.name,
        id: s.id,
      },
      geometry: s.geometry,
    }
  }),
})

export const getStrataSamplePointsGeoJSON = (expectedPoints, strata) => ({
  expectedStrata: getExpectedStrataGeoJSON(strata),
  expectedSamplePoints: getExpectedSamplePointsGeoJSON(expectedPoints),
})

export const getCollectedSamplePoints = (collectedPoints) => ({
  type: 'FeatureCollection',
  features: collectedPoints.map((p) => {
    return {
      type: 'Feature',
      properties: {
        name: p.name,
        id: p.id,
      },
      geometry: p.sample_point,
    }
  }),
})

export const getPercentage = (valueToCalculatePercentage, totalValue) => {
  if (!totalValue) return 0
  const percentage = (valueToCalculatePercentage / totalValue) * 100
  return percentage
}

export const getValueByPercentage = (percentageToGetValue, totalValue) => {
  if (!totalValue) return 0
  const value = (percentageToGetValue * totalValue) / 100
  return value.toFixed(2)
}

export const getLatestStrata = (strataList) => {
  return (
    strataList?.reduce((prevItem, currentItem) => {
      if (
        !prevItem ||
        new Date(currentItem.strata_start_timestamp) >
          new Date(prevItem.strata_start_timestamp)
      ) {
        return currentItem
      } else {
        return prevItem
      }
    }, null) || {}
  )
}

/**
 * Given a field value, returns the corresponding field name for sorting or filtering.
 * Maps 'area_in_hectares' to 'area', 'administrative_area' to 'state',
 * 'co2e_sequestration_rate' to 'soc_sequestration_rate_per_hectare_per_year',
 * and 'farm_name' to 'farm_name'. All other fields are returned as is.
 * The fieldValue parameter represents the field name of the column to be sorted or filtered.
 * The function returns the corresponding key needed to be sent in the sort or filter requests.
 * @param {string} fieldValue - the field value to map
 * @returns {string} - the mapped field name
 */
export const getFieldValue = (fieldValue) => {
  switch (fieldValue) {
    case 'area_in_hectares':
      return 'area'
    case 'administrative_area':
      return 'state'
    case 'co2e_sequestration_rate':
      return 'soc_sequestration_rate_per_hectare_per_year'
    case 'farm_name':
      return 'farm_name'
    case 'soc_sequestration_rate_in_metric_tons_of_carbon_per_hectare_per_year':
      return 'soc_sequestration_rate_per_hectare_per_year'
    default:
      return fieldValue
  }
}

export const pruneData = (data, ignoredAttributes) => {
  // If no data, return error
  if (!data || !data.length) {
    throw new MissingFieldFormDataError('No data provided')
  }

  // Remove ignored attributes from data objects
  const result = data.map((d) => _omit(d, ignoredAttributes))

  return result
}

/**
 * Removes specified attributes from each object in the field form responses data array.
 *
 * @param {Array<Object>} fieldForm - The array of objects to filter.
 * @return {Array<Object>} The filtered array of objects.
 * @throws {MissingFieldFormDataError} If fieldForm is undefined.
 */
const pruneFieldFormData = (fieldForm) => {
  return pruneData(
    fieldForm,
    ATTRIBUTES_IGNORED_WHEN_EXPORTING.IGNORED_FORM_ATTRIBUTES
  )
}

/**
 * Removes specified attributes from each object in the field form responses data array.
 *
 * @param {Array<Object>} samplePoint - The array of objects to filter.
 * @return {Aray<Object>} The filtered sample point object
 * @throws {MissingFieldFormDataError} If samplePoint is undefined.
 */
const pruneSamplePointsData = (samplePoints) => {
  return pruneData(
    samplePoints,
    ATTRIBUTES_IGNORED_WHEN_EXPORTING.IGNORED_SAMPLE_POINTS_ATTRIBUTES
  )
}

/**
 * Converts the field form data into a 2D array for react-csv.
 * Returns an empty array if there is no data because the form hasn't been sampled yet.
 *
 * @param {Object} field - The field object containing the field conditions.
 * @param {Object} [options] - The options object.
 * @param {boolean} [options.includeHeader=true] - Whether to include the header row in the resulting array.
 * @return {Array<Array<*>> | []} The field form data as a 2D array, or an empty array if there is no data.
 * @throws Will throw an error if there is a problem pruning the in-field conditions form data.
 */
export const getInFieldConditionsAsCSVArray = (
  field,
  options = { includeHeader: true }
) => {
  let outputFormData = []
  try {
    const filteredFormData = pruneFieldFormData(field.fieldConditions)

    // Add header column if needed
    if (options.includeHeader) {
      outputFormData = [Object.keys(filteredFormData[0])]
    }

    // Add field form data
    outputFormData.push(...filteredFormData.map((f) => Object.values(f)))
  } catch (err) {
    if (err instanceof MissingFieldFormDataError) {
      // The field hasn't been sampled, so there's no data
      return []
    } else {
      throw err
    }
  }
  return outputFormData
}

/**
 * Converts the field form data into a CSV string using PapaParse.
 *
 * @param {Object} field - The field object containing the field conditions.
 * @return {string} The field form data as a CSV string.
 * @throws {MissingFieldFormDataError} If there is an error pruning the field form data.
 */
export const convertFieldToCSV = (field) => {
  let filteredFormData
  try {
    filteredFormData = pruneFieldFormData(field.fieldConditions)
  } catch (err) {
    console.error(
      `Error pruning field form data. Field name: ${field?.fieldName}`,
      { err }
    )
    throw err
  }

  return Papa.unparse(filteredFormData)
}

/**
 * Converts the field sample point data into a CSV string using PapaParse.
 *
 * @param {Object} samplepoints - The field object containing the sample points.
 * @return {string} The field sample point data as a CSV string.
 * @throws {MissingFieldFormDataError} If there is an error pruning the sample point data.
 */
export const convertSamplePointsToCSV = (samplePoints) => {
  let filteredSamplePointsData
  try {
    filteredSamplePointsData = pruneSamplePointsData(samplePoints)
  } catch (err) {
    console.error(`Error pruning sample points data`, { err })
    throw err
  }

  return Papa.unparse(filteredSamplePointsData)
}
